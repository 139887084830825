.academic-products-intro {
  p {
    font-size: 16px;
    line-height: 32px;
  }
  .content {
    display: flex;
    align-items: center;
    @media #{$md-layout} {
      order: 2;
    }
    @media #{$sm-layout} {
      order: 2;
    }
  }
  .image {
    @media #{$md-layout} {
      order: 1;
      margin-bottom: 40px;
    }
    @media #{$sm-layout} {
      order: 1;
      margin-bottom: 40px;
    }
  }
}

.color-bar-wrapper {
  margin-top: 120px;
  .color-bar-header-container {
    display: flex;
    flex-wrap: nowrap;
    height: 92px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 100;
    @media #{$md-layout} {
      display: none;
    }
    @media #{$sm-layout} {
      display: none;
    }
    .color-bar-header {
      width: 200px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      p {
        margin: 0;
        font-size: 24px;
        color: white;
        text-align: center;
      }
    }
  }
  .color-bar-body {
    display: flex;
    flex-wrap: nowrap;
    height: 2380px;
    position: relative;
    @media #{$md-layout} {
      display: none;
    }
    @media #{$sm-layout} {
      display: none;
    }
    .color-bar {
      width: 200px;
      height: 100%;
    }
    .transparent-body {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: transparent;
      padding-top: 20px;
      img {
        display: block;
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
  }
  .color-bar-horizontal {
    width: 100%;
    display: none;

    img {
      &:first-child {
        margin-top: 30px;
      }
      width: 100%;
      margin-bottom: 20px;
      height: 170px;
      @media #{$sm-layout} {
        height: 150px;
      }
      @media #{$large-mobile} {
        height: 120px;
      }
    }
    @media #{$md-layout} {
      display: block;
    }
    @media #{$sm-layout} {
      display: block;
    }
  }
}

.find-program {
  margin-top: 100px;
}

.academic-steam {
  background-color: #03BD8D;
  padding: 0 60px;
  height: 374px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-size: 36;
    color: white;
    margin-bottom: 30px;
  }
  p {
    margin: 0;
    color: white;
    font-size: 16px;
  }
}

.steam-vocational-body {
  @media #{$lg-layout} {
    margin-bottom: -720px; 
  }
  @media #{$md-layout} {
    margin-bottom: -960px; 
  }
  @media #{$sm-layout} {
    margin-bottom: -980px; 
  }
}

.academic-prod-serv {
  @media #{$lg-layout} {
    // margin-bottom: -720px; 
  }
  @media #{$md-layout} {
    margin-bottom: 100px; 
  }
  @media #{$sm-layout} {
    margin-bottom: -1180px; 
  }
}

.academic-curriculum-table {
  table {
    border-radius: 10px;
    border: 1px solid #B3B3B3;
    border-collapse: separate;
    tr:nth-child(2) {
      background-color: #F1FEFF;
    }
    tr:nth-child(4) {
      background-color: #F1FEFF;
    }
    tr:last-child {
      height: 30px;
      background-color: #26A69A;
      width: 100%;
      td {
        border-radius: 0px 0px 10px 10px;
      }
    }
  }
  td {
    text-align: left;
    border-left: 1px solid #B3B3B3;
    border-top: 1px solid #B3B3B3;
    padding: 10px 6px 16px 16px;
    font-size: 16px;
    line-height: 30px;
  }

  th {
    text-align: center;
    border-top: none;
    text-transform: none;
    font-size: 24px;
    font-weight: 500;
    color: white;
    line-height: 32px;
    padding: 20px;

    &:nth-child(odd) {
      background-color: #26A69A;
    }
    &:nth-child(even) {
      background-color: #219A8F;
    }
    &:first-child {
      border-radius: 10px 0px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 10px 0px 0px;
    }
  }

  td:first-child, th:first-child {
      border-left: none;
  }
  th {
    height: 168px;
  }
  
}