

.why-should, .coding-steam-cert {
  h2 {
    font-size: 50px;

    @media #{$md-layout} {
      font-size: 40px;
    }
    @media #{$sm-layout} {
      font-size: 30px;
    }
  }
  
}
.coding-cert-numbers {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  align-items: center;
  text-align: center;
  padding: 50px 20px;
  height: 575px;
  background-clip: border-box;
  @media #{$sm-layout} {
    height: 500px;
    padding: 40px 10px 10px 10px;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 230px;
    height: 230px;
    border-radius: 115px;

    @media #{$smlg-device} {
      width: 200px;
      height: 200px;
      border-radius: 100px;
    }

    h3 {
      color: white;
      font-size: 50px;
      font-weight: 500;
      margin: 0;
      @media #{$smlg-device} {
        font-size: 40px;
      }
    }
    
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #252B42;
    margin-top: 40px;
    @media #{$smlg-device} {
      font-size: 16px;
    }
  } 
}

.coding-cert-card-holder {
  margin-top: 50px;
   flex-wrap: nowrap;
   margin-left: -4px;

   @media #{$md-layout} {
    flex-wrap: wrap;
  }

  @media #{$sm-layout} {
    flex-wrap: wrap;
  }

   .coding-cert-cards {
    width: 430px; 
    height: 215px; 
    background-color: #F2F2F2; 
    position: relative; 
    border-radius: 20px; 
    display: flex; 
    align-items: center;
    padding: 40px 39px 40px 80px;
    margin: 10px 0;

    &:nth-child(2) {
      margin-left: -26px;
      @media #{$sm-layout} {
        margin-left: 0; 
      }
    }

    &:nth-child(3) {
      margin-left: -26px;

      @media #{$md-layout} {
        margin-left: 0; 
      }
      @media #{$sm-layout} {
        margin-left: 0; 
      }
    }

    @media #{$md-layout} {
      width: 350px; 
    }

    @media #{$sm-layout} {
      width: 430px;
      padding: 20px 10px 20px 80px; 
    }
    
    p {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      line-height: 1.714286;

      @media #{$smlg-device} {
        font-size: 14px;
      }

      @media #{$sm-layout} {
        font-size: 14px !important;
        line-height: 1.714286 !important;
      }
    }
  }
}

.enhance-teach-content, .stay-ahead-content, .elevate-team-content, .enhance-hands-content {
  // padding-right: 60px;
  h2 {
    font-size: 36px;
    @media #{$sm-layout} {
      font-size: 30px;
    }
  }
  @media #{$md-layout} {
    order: 2;
    padding-right: 0;
  }

  @media #{$sm-layout} {
    order: 2;
    padding-right: 0; 
  }
}

.stay-ahead-image, .enhance-teach-image {
  @media #{$md-layout} {
    order: 1;
  }

  @media #{$sm-layout} {
    order: 1; 
  }
}

.coding-cert-contact {
  @media #{$md-layout} {
    margin-bottom: -600px;
  }

  @media #{$sm-layout} {
    margin-bottom: -700px;
  }
}

