/* popUp.css */

.popup-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow: auto;
}

.popup-content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    max-height: 100vh; /* Set a max height for the popup */
    overflow-y: auto; /* Allow vertical scrolling if content exceeds max height */
    z-index: 10000;
    animation: fadeIn 0.5s;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: #333; /* Visible color for the button */
    cursor: pointer;
    z-index: 10001; /* Higher z-index to ensure it appears above the form */
    padding: 5px; /* Add padding for easier tapping */
}

.close-button:hover {
    color: #ff0000; /* Highlight the button on hover */
}

