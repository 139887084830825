.education-hero {
  padding-bottom: 152px;
  padding-top: 306px;
  height: 600px;
  background-position: top top;
  position: relative;
  h2 {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  @media (max-width: 575px) {
    height: 500px;
    padding-top: 250px;
    h2 {
      font-size: 36px;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: 600px;
    padding-top: 360px;
    h2 {
      font-size: 40px;
    }
  }
}

.about_bg {
  height: 500px;
  @media #{$md-layout} {
    height: auto;
    background-position: center center;
  }
  @media #{$smd-layout} {
    // height: 560px;
    height: auto;
    background-position: center center;
  }
  @media #{$large-mobile} {
    // height: 520px;
    height: auto;
    background-position: center center;
    padding: 20px 0;
  }

  @media #{$tiny-mobile} {
    // height: 520px;
    height: auto;
 }
 @media #{$x-tiny-mobile} {
    // height: 520px;
    height: auto;
 }
}

.vocational_bg {
  height: 500px;
  @media #{$md-layout} {
    // height: 720px;
    height: auto;
    background-position: center center;
  }
  // @media #{$smd-layout} {
  //   height: 660px;
  //   background-position: center center;
  // }

  @media #{$sm-layout} {
    height: auto;
    background-position: center center;
  }
  // @media #{$large-mobile} {
  //   height: 620px;
  //   background-position: center center;
  //   padding: 20px 0;
  // }

//   @media #{$tiny-mobile} {
//     height: 640px;
//  }
//  @media #{$x-tiny-mobile} {
//     height: 640px;
//  }
}

.about_bg, .vocational_bg {
  
  
 .about_hero_content {
    h2 {
      font-size: 50px; 
      font-weight: 600; 
      margin-bottom: 20px; 
      color: white;
      
      @media #{$md-layout} {
        margin-bottom: 0; 
        text-align: center;
      }
      @media #{$sm-layout} {
        margin-bottom: 0;
        font-size: 40px;  
        text-align: center;
      }
      @media #{$tiny-mobile} {
        font-size: 30px;
        text-align: center; 
      }
    }
    p {
      color: white;
      font-size: 20px;
      @media #{$md-layout} {
        text-align: center; 
      }
      @media #{$sm-layout} {
        text-align: center; 
      }
    }
    @media #{$md-layout} {
      order: 2;
    }
    @media #{$sm-layout} {
      order: 2;
    }
 }
 .about_hero_image {
    @media #{$md-layout} {
      margin-bottom: 80px;
      order: 1;
    }
    @media #{$sm-layout} {
      margin-bottom: 80px;
      order: 1;
    }
 }
  
  img {
    width: 100%;
  }
}

.home_bg {
  background-color: rgb(42, 157, 244);
  display: flex;
  height: 580px;
  background-position: center top;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px;

  .row {
    @media #{$md-layout} {
      flex-direction: column;
      align-items: center;
    }
    @media #{$sm-layout} {
      flex-direction: column;
      align-items: center;
    }
  }

  @media #{$md-layout} {
    height: 650px;
    background-position: center center;
  }
  @media #{$smd-layout} {
    height: 560px;
    background-position: center center;
  }
  @media #{$large-mobile} {
    height: 450px;
    background-position: center center;
    padding: 20px 0;
  }

  @media #{$tiny-mobile} {
    height: 560px;
 }
 @media #{$x-tiny-mobile} {
    height: 560px;
 }

  h1 {
    font-size: 47px; 
    font-weight: 700;
    margin-bottom: 20px; 
    color: white; 
    text-transform: none;
    margin-bottom: 10;
    line-height: 0;
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media #{$md-layout} {
      font-size: 38px;
    }
    @media #{$sm-layout} {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
  .hero_text {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    @media #{$md-layout} {
      margin-top: 0;
      order: 2;
    }
    @media #{$sm-layout} {
      margin-top: 0;
      order: 2;
    }
    // flex: 2;
  }
  h2 {
    font-size: 51px;
    font-weight: 500;
    margin-bottom: 20px;
    color: white;
    margin-bottom: 40px;
    // text-transform: uppercase;
    // text-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;

    @media #{$md-layout} {
      font-size: 38px;
    }
    @media #{$sm-layout} {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  .description {
    margin: 0px;
    font-size: 22px;
    font-weight: 400;
    color: white;

    @media #{$md-layout} {
      font-size: 18px !important;
    }
    @media #{$sm-layout} {
      font-size: 14px !important;
    }
  }

  h1, h2, p {
    @media #{$md-layout} {
      text-align: center;
    }
    @media #{$sm-layout} {
      text-align: center;
    }
  }

  .home_img {
    // flex: 1;
    width: 70%;
   

    // @media #{$enlarged-layout} {

    // }
    margin-right: -200px;

    @media #{$lg-layout} {
      margin-right: -120px;
    }

    @media #{$laptop-device} {
      margin-right: -100px;
    }

    // @media #{$extra-device} {
    //   margin-right: -200px;
    // }

    @media #{$md-layout} {
      width: 100%;
      margin-right: 0;
      margin-left: -70px;
      order: 1;
      margin-bottom: 60px;
    }
    @media #{$sm-layout} {
      width: 100%;
      margin-right: 0;
      margin-left: -70px;
      order: 1;
      margin-bottom: 60px;
    }

    // @media #{$md-layout} {
    //   align-self: center;
    //   margin-left: -70px;
    //   margin-top: 30px;
    // }
    // @media #{$sm-layout} {
    //   align-self: center;
    //   margin-left: -40px;
    //   margin-top: 30px;
    // }

    // img {
    //   // transform: scale(1.2);
    //   // height: 100%;

    //   @media #{$md-layout} {
    //     transform: scale(1);
    //   }
    //   @media #{$sm-layout} {
    //     transform: scale(1);
    //   }
    // }
  }

  .container > div {
    align-items: center; 
    flex-wrap: nowrap;

    @media #{$md-layout} {
      flex-wrap: wrap;
    }
    @media #{$sm-layout} {
      flex-wrap: wrap;
    }
  }
}