.container {
  transform: scale(0.8);
  
  @media #{$small-mobile} {
    margin: 0;
    width: 100%;
  }

  @media #{$tiny-mobile} {
     margin: 0;
     width: 100%;
     transform: scale(1);
  }
  @media #{$x-tiny-mobile} {
    margin: 0;
    width: 100%;
    transform: scale(1);
  }
}

.chatbot-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  // transform: scale(0.8);

  @media #{$md-layout} {
    margin-top: -100px;
  }
  @media #{$sm-layout} {
    margin-top: -100px;
  }

  @media #{$tiny-mobile} {
    margin-top: 0;
 }
 @media #{$x-tiny-mobile} {
    margin-top: 0;
 }

  h2 {
    font-size: 40px;
    text-align: center;

    @media #{$md-layout} {
      margin-top: 80px;
    }

    @media #{$sm-layout} {
      font-size: 30px;
      // margin-bottom: 40px;
      margin-top: 80px;
    }
  }
}

.chatbot_container {
  display: flex; 
  // justify-content: space-between;
  flex-direction: column; 
  align-items: center; 
  width: 100%;

  .chatbot_lg {
    @media #{$md-layout} {
      display: none;
    }
    @media #{$sm-layout} {
      display: none;
    }
  }

  .chatbot_sm {
    display: none;
    @media #{$md-layout} {
      display: block;
      margin-bottom: 20px;
    }
    @media #{$sm-layout} {
      display: block;
      margin-bottom: 20px;
    }
  }

  .chatbot_features {
    display: none;
    @media #{$md-layout} {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      align-self: flex-start;
    }
    @media #{$sm-layout} {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      align-self: flex-start;
    }
  }

  // .section_1, .section_2, .section_3 {
  //   display: flex; 
  //   flex-direction: column; 
  //   align-items: center;
  // }

  // .section_2 {
  //   @media #{$md-layout} {
  //     order: 1;
  //     align-items: flex-start;
  //   }
  //   @media #{$sm-layout} {
  //     order: 1;
  //     align-items: flex-start;
  //   }
  //   .section_2_badge {
  //     display: flex;
  //     flex-direction: column;
  //     width: 250px;
  //     align-items: center;
  //     margin-right: -90px;

  //     @media #{$md-layout} {
  //       order: 2;
  //       flex-direction: row;
  //       width: 100%;
  //       margin-bottom: 20px;
  //     }
  //     @media #{$sm-layout} {
  //       order: 2;
  //       flex-direction: row;
  //       width: 100%;
  //       margin-bottom: 20px;
  //     }

  //     img {
  //       margin-bottom: 20px; 
  //       width: 46%;

  //       @media #{$md-layout} {
  //         width: 18%;
  //         margin-bottom: 0; 
  //       }
  //       @media #{$sm-layout} {
  //         width: 16%;
  //         margin-bottom: 0; 
  //       }
  //     }
  //     p {
  //       font-size: 20px;
  //       font-weight: 500;
  //       margin: 0;
  //       text-align: center;

  //       @media #{$md-layout} {
  //         font-size: 16px;
  //         text-align: left; 
  //       }
  //       @media #{$sm-layout} {
  //         font-size: 14px;
  //         text-align: left; 
  //       }
  //      }
  //   }
  //   .mascot {
  //     position: relative; 
  //     width: 500px; 
  //     margin-right: -90px;

  //     .mascot_2nd {
  //       position: absolute;
  //       left: -100px;
  //       bottom: -90px;
  //       width: 78%;

  //       @media #{$md-layout} {
  //         width: 60%;
  //         left: auto;
  //         right: 30px;
  //         bottom: -40px;
  //       }
  //       @media #{$sm-layout} {
  //         width: 50%;
  //         left: auto;
  //         right: 60px;
  //         bottom: -40px;
  //       }
  //     }

  //    .mascot_1st {
  //       width: 100%;

  //       @media #{$md-layout} {
  //         width: 90%;
  //       }
  //       @media #{$sm-layout} {
  //         width: 80%;
  //       }
  //     }

  //     @media #{$md-layout} {
  //       order: 1;
  //       margin-bottom: 50px;
  //       align-self: center;
  //     }
  //     @media #{$sm-layout} {
  //       order: 1;
  //       margin-bottom: 50px;
  //       align-self: center;
  //     }
  //   }
  // }

  // .section_1 {
  //   @media #{$md-layout} {
  //     order: 2;
  //   }
  //   @media #{$sm-layout} {
  //     order: 2;
  //   }
  // }

  // .section_3 {
  //   @media #{$md-layout} {
  //     order: 3;
  //   }
  //   @media #{$sm-layout} {
  //     order: 3;
  //   }
  // }

  // .section_1, .section_3 {
  //   > div {
  //     display: flex; 
  //     flex-direction: column;  
  //     width: 270px;
  //     align-items: center;

  //     @media #{$md-layout} {
  //       flex-direction: row;
  //       width: 100%;
  //       margin-bottom: 20px;
  //     }
  //     @media #{$sm-layout} {
  //       flex-direction: row;
  //       width: 100%;
  //       margin-bottom: 20px;
  //     }

  //     &:not(:last-child) {
  //       margin-bottom: 80px;
  //       @media #{$md-layout} {
  //         margin-bottom: 20px;
  //       }
  //       @media #{$sm-layout} {
  //         margin-bottom: 20px;
  //       }
  //     }
  //     img {
  //       margin-bottom: 20px; 
  //       width: 46%;
  //       @media #{$md-layout} {
  //         width: 18%;
  //         margin-bottom: 0; 
  //       }
  //       @media #{$sm-layout} {
  //         width: 16%;
  //         margin-bottom: 0; 
  //       }
  //     }
  //     p {
  //       font-size: 20px; 
  //       font-weight: 500;
  //       margin: 0; 
  //       text-align: center;

  //       @media #{$md-layout} {
  //         font-size: 16px;
  //         text-align: left; 
  //       }
  //       @media #{$sm-layout} {
  //         font-size: 14px;
  //         text-align: left; 
  //       }
  //     }
  //   }
  // }
}

.fautures_1_image {
  @media #{$md-layout} {
    margin-bottom: 30px;
  }
  @media #{$sm-layout} {
    margin-bottom: 30px;
  }
}

.feature_2 {
  @media #{$tiny-mobile} {
    margin-top: 50px;
 }
 @media #{$x-tiny-mobile} {
    margin-top: 50px;
 }
}

.features_2_image {
  @media #{$md-layout} {
    order: 1;
    margin-bottom: 30px;
  }
  @media #{$sm-layout} {
    order: 1;
    margin-bottom: 30px;
  }
}

.features_2_text {
  @media #{$md-layout} {
    order: 2;
  }
  @media #{$sm-layout} {
    order: 2;
  }
}

.feature_3 {
  margin-top: -20px;
  @media #{$md-layout} {
    margin-top: -50px;
  }
  @media #{$sm-layout} {
    margin-top: -50px;
  }

  @media #{$tiny-mobile} {
    margin-top: 50px;
 }
 @media #{$x-tiny-mobile} {
    margin-top: 50px;
 }

  .features_3_image {
    @media #{$md-layout} {
      margin-bottom: 30px;
    }
    @media #{$sm-layout} {
      margin-bottom: 30px;
    }
  }
}

#features_section {
  margin-top: -30px;
  @media #{$md-layout} {
    margin-top: -120px;
  }
  @media #{$sm-layout} {
    margin-top: -120px;
  }

  @media #{$tiny-mobile} {
    margin-top: 80px;
 }
 @media #{$x-tiny-mobile} {
    margin-top: 80px;
 }
}

.service-section {
  margin-top: -100px;
  @media #{$md-layout} {
    margin-top: -320px;
  }
  @media #{$sm-layout} {
    margin-top: -360px;
  }

  @media #{$tiny-mobile} {
    margin-top: 80px;
 }
 @media #{$x-tiny-mobile} {
    margin-top: 80px;
 }

  #service-image {
    width: 60%;

    @media #{$md-layout} {
      width: 50%;
    }
    @media #{$sm-layout} {
      width: 40%;
    }
  }

  .service-card {
    h5 {
      font-size: 30px;
      @media #{$tiny-mobile} {
        font-size: 26px;
      }
      @media #{$x-tiny-mobile} {
        font-size: 20px;
      }
    }
    
  }
}

.mission-image {
  @media #{$md-layout} {
    margin-bottom: 50px;
    order: 2;
  }
  @media #{$sm-layout} {
    margin-bottom: 50px;
    order: 2;
  }
}

.mission {
  @media #{$md-layout} {
    margin-bottom: 50px;
    order: 1;
  }
  @media #{$sm-layout} {
    margin-bottom: 50px;
    order: 1;
  }
}

.vision-image {
  @media #{$md-layout} {
    display: none;
  }
  @media #{$sm-layout} {
    display: none;
  }
}

#mission-vision {
  @media #{$sm-layout} {
    margin-top: -200px;
  }
  @media #{$small-mobile} {
    margin-top: -280px;
  }

  @media #{$tiny-mobile} {
    margin-top: 0;
 }
 @media #{$x-tiny-mobile} {
    margin-top: 0;
 }
}

.home-portfolio {
  margin-top: -130px;
  @media #{$md-layout} {
    margin-top: -150px;
  }
  @media #{$sm-layout} {
    margin-top: -200px;
  }
  @media #{$small-mobile} {
    margin-top: -270px;
  }

  @media #{$tiny-mobile} {
    margin-top: 100px;
 }
 @media #{$x-tiny-mobile} {
    margin-top: 100px;
 }
}

.products-area {
  margin-top: 50px;
  @media #{$md-layout} {
    margin-top: 0;
  }
  @media #{$sm-layout} {
    margin-top: 350px;
  }
  @media #{$small-mobile} {
    margin-top: 0;
  }
  @media #{$tiny-mobile} {
    margin-top: 80px;
 }
 @media #{$x-tiny-mobile} {
    margin-top: 80px;
 }
  .section-title {
    @media #{$smd-layout} {
      margin-top: -400px;
    }
    @media #{$large-mobile} {
      margin-top: -200px;
    }

    @media #{$tiny-mobile} {
      margin-top: 0;
   }
   @media #{$x-tiny-mobile} {
      margin-top: 0;
   }
  }
  .products-section {
    @media #{$smd-layout} {
      margin-top: -200px;
    }
    @media #{$small-mobile} {
      margin-top: -50px;
    }

    @media #{$tiny-mobile} {
      margin-top: 0;
   }
   @media #{$x-tiny-mobile} {
      margin-top: 0;
   }
  }
}

#testimonial {
  > div {
    margin-top: -60px;
    @media #{$smd-layout} {
      margin-top: -200px;
    }
    @media #{$md-layout} {
      margin-top: -200px;
    }
    @media #{$large-mobile} {
      margin-top: 20px;
    }

    @media #{$tiny-mobile} {
      margin-top: 480px;
   }
   @media #{$x-tiny-mobile} {
      margin-top: 480px;
   }
  }

  .testimonial-title {
    margin-bottom: 70px;
    @media #{$sm-layout} {
      margin-bottom: 30px;
    }
    @media #{$small-mobile} {
      margin-bottom: 30px;
    }
  }

  .testimonial-card {
    padding: 80px 50px 20px;

    @media #{$tiny-mobile} {
      padding: 80px 10px;
   }
   @media #{$x-tiny-mobile} {
      padding: 80px 10px;
   }
  }
  .testimonial-body {
    @media #{$tiny-mobile} {
      font-size: 12px !important;
   }
   @media #{$x-tiny-mobile} {
      font-size: 12px !important;
   }
  }
}

.home-contact {
  margin-top: -60px;
  @media #{$md-layout} {
    margin-top: -150px;
  }
  @media #{$sm-layout} {
    margin-top: -150px;
  }
  @media #{$small-mobile} {
    margin-top: -200px;
  }

  @media #{$tiny-mobile} {
    margin-top: 0;
 }
 @media #{$x-tiny-mobile} {
    margin-top: 0;
 }
}

.home-brand {
  @media #{$md-layout} {
    margin-bottom: -100px;
  }

  @media #{$sm-layout} {
    margin-bottom: -100px;
  }

  @media #{$small-mobile} {
    margin-bottom: -140px;
    margin-top: -30px;
  }

  @media #{$tiny-mobile} {
    margin-top: 0;
    margin-bottom: 50px;
 }
 @media #{$x-tiny-mobile} {
    margin-top: 0;
    margin-bottom: 50px;
 }
}