.team_1_content, .team_2_content {
  align-items: flex-start;
  @media #{$md-layout} {
    align-items: center;
    padding-top: 40px;
  }
  @media #{$sm-layout} {
    align-items: center;
    padding-top: 40px;
  }
  p {
    @media #{$md-layout} {
      text-align: center;
    }
    @media #{$sm-layout} {
      text-align: center;
    }
  }
}

.full_team {
  padding: 80px 50px;
  @media #{$md-layout} {
    margin-top: -100px;
    padding-bottom: 60px;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  @media #{$sm-layout} {
    margin-top: -100px;
    padding-bottom: 60px;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  @media #{$large-mobile} {
    margin-top: -100px;
  }
  @media #{$tiny-mobile} {
    margin-top: 50px;
 }
  @media #{$x-tiny-mobile} {
    margin-top: 50px;
  }

  h4 {
    @media #{$md-layout} {
      text-align: center;
    }
  
    @media #{$sm-layout} {
      text-align: center;
    }
  }
}

.team_header {
  justify-content: space-around; 
  align-items: center;
  > div {
    @media #{$md-layout} {
      display: none;
    }
    @media #{$sm-layout} {
      display: none;
    }
  }

  @media #{$md-layout} {
    align-items: flex-start;
    flex-direction: column;
  }
  @media #{$sm-layout} {
    align-items: flex-start;
    flex-direction: column;
  }
}

.about-team-area {
  margin-top: 50px;
  @media #{$md-layout} {
    // margin-top: -50px;
  }
  @media #{$sm-layout} {
    // margin-top: -100px;
  }
}

.about-portfolio-area {
  margin-top: -120px;
  @media #{$md-layout} {
    margin-top: -240px;
  }
  @media #{$smd-layout} {
    margin-top: -280px;
  }
  @media #{$large-mobile} {
    margin-top: -600px;
  }
  @media #{$tiny-mobile} {
    margin-top: 80px;
 }
  @media #{$x-tiny-mobile} {
    margin-top: 80px;
  }
}

.about-brand-area {
  
  @media #{$md-layout} {
    margin-top: -100px;
  }

  @media #{$sm-layout} {
    margin-top: -100px;
  }
  @media #{$large-mobile} {
    margin-top: -260px;
  }
  @media #{$tiny-mobile} {
    margin-top: 50px;
 }
  @media #{$x-tiny-mobile} {
    margin-top: 50px;
  }
}

.about-contact-us {
  margin-top: -100px;
  margin-bottom: -100px;
  @media #{$tiny-mobile} {
    margin-top: 10px;
 }
  @media #{$x-tiny-mobile} {
    margin-top: 10px;
  }
}