.casestudy-body {
  @media #{$lg-layout} {
    margin-bottom: -520px; 
  }
  @media #{$md-layout} {
    margin-bottom: -660px; 
  }
  @media #{$sm-layout} {
    margin-bottom: -720px; 
  }
  @media #{$large-mobile} {
    margin-bottom: -900px; 
  }
}