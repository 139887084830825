.product-vocational-body {
  margin-bottom: -300px;
  @media #{$lg-layout} {
    margin-bottom: -320px; 
  }
  @media #{$md-layout} {
    margin-bottom: -360px; 
  }
  @media #{$sm-layout} {
    margin-bottom: -780px; 
  }

  .card-body {
    h5 {
      font-size: 30px;
      font-weight: 700;

      @media #{$md-layout} {
        font-size: 20px;
      }
      @media #{$sm-layout} {
        font-size: 20px;
      }
    }
  }
}

.demo-vocational-body {
  @media #{$lg-layout} {
    margin-bottom: -520px; 
  }
  @media #{$md-layout} {
    margin-bottom: -960px; 
  }
  @media #{$sm-layout} {
    margin-bottom: -1060px; 
  }

  .enroll-pilot-image {
    width: 571px;
    @media #{$lg-layout} {
      width: 100%; 
    }
    @media #{$md-layout} {
      width: 100%; 
    }
    @media #{$sm-layout} {
      width: 100%; 
    }
  }
}

.curriculum-vocational-body {
  @media #{$lg-layout} {
    margin-bottom: -400px; 
  }
  @media #{$md-layout} {
    margin-bottom: -800px; 
  }
  @media #{$sm-layout} {
    margin-bottom: -700px; 
  }
}

.vocational-collapsible {
  padding: 0 70px;
  
  @media #{$md-layout} {
    padding: 0 50px; 
  }
  @media #{$sm-layout} {
    padding: 0 0px; 
  }
}