#hero-tabs-container {
  &.school {
    bottom: 0px;

    @media (max-width: 991px) {
      bottom: -354px;
    }
  }
  &.academic {
    bottom: -40px;

    @media (max-width: 991px) {
      bottom: -354px;
    }
  }
  border: none;
  transform: scale(1);
  box-shadow: rgb(158 162 160 / 10%) 10px 30px 40px;
  border-radius: 10px;
  height: 80px;
  position: absolute;
  
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  display: flex;
  flex-direction: row;
  background-color: #fff;
  background-clip: border-box;
  padding: 0px;
  transform-origin: center bottom;
  z-index: 100;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    bottom: -354px;
    height: fit-content;
    transform-origin: center top;
    box-shadow: none;
    border-radius: 0%;
  }
}
.hero-tab {
  padding: 15px 40px;
  position: relative;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    opacity: 0.2;
    border-right: 1px solid #221314;
    right: 0;
    top: 30%;
    bottom: 30%;
  }
  @media (max-width: 991px) {
    border: 1px solid rgba(37, 37, 37, 0.18);
    margin-bottom: 20px;
    border-radius: 7px;
    height: 50px;
    width: 600px;
    background-color: #fff;
    &::after {
      content: '';
      position: absolute;
      border: 0;
    }
  }

  @media (max-width: 575px) {
    width: 300px;
  }
  p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}

.hero-tab-selected {
  background: linear-gradient(180deg, #FE5B3E 0%, #FE7B49 100%);
  
  &::after {
    content: '';
    position: absolute;
    border: 0;
  }
  p {
    color: white;
  }
}

.hero-tab-last {
  padding: 15px 40px;
  position: relative;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0px 10px 10px 0px;
  @media (max-width: 991px) {
    border: 1px solid rgba(37, 37, 37, 0.18);
    margin-bottom: 20px;
    border-radius: 7px;
    height: 50px;
    width: 600px;
    background-color: #fff;
    &::after {
      content: '';
      position: absolute;
      border: 0;
    }
  }

  @media (max-width: 575px) {
    width: 300px;
  }
 
  p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}

.hero-tab-first {
  padding: 15px 40px;
  position: relative;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px 0px 0px 10px;
  @media (max-width: 991px) {
    border: 1px solid rgba(37, 37, 37, 0.18);
    margin-bottom: 20px;
    border-radius: 7px;
    height: 50px;
    width: 600px;
    background-color: #fff;
    &::after {
      content: '';
      position: absolute;
      border: 0;
    }
  }

  @media (max-width: 575px) {
    width: 300px;
  }
 
  p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}